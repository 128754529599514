import React from "react"
import PropTypes from "prop-types"

import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import { ro } from "date-fns/locale"

import { graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Avatar from "@material-ui/core/Avatar"


import GeneralContact from "../components/contacts/GeneralContact"
import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"

import Logo from "../img/avatar.png"

const useStyles = makeStyles(theme => ({
  date: {
    color: theme.palette.gray,

  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  question: {
    fontStyle: "italic",
  },
  formContainer: {
    padding: theme.spacing(3.2),
  },
  answer: {
    padding: theme.spacing(3.2),
  },
  avatar: {
    margin: 0,
    width: 40,
    height: 40,
  },
  sendButton: {
    marginTop: theme.spacing(1),
  },
}))

const QuestionItemPage = props => {
  const {
    data: { questionItem },
    pageContext: { locale, breadcrumbs },
    location,
  } = props

  const classes = useStyles()

  const schema = questionItem.data.schema_json
    ? questionItem.data.schema_json.text
    : ""
  const ogp = questionItem.data.ogp_tags || ""
  const meta = {
    title: questionItem.data.meta_title,
    description: questionItem.data.meta_description,
    keywords: questionItem.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        noindex={questionItem.data.noindex === false ? false : true}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <GeneralContact
                location={location}
                locale={locale}
                redirectTo={`${location.pathname}multumim/`}
                formName="Question Form"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid
                container
                spacing={4}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item key={questionItem.id} xs={12} md={10}>
                  <Grid container spacing={4}>
                    <Grid item lg={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        className={classes.date}
                      >
                        {`${format(
                          parseISO(
                            questionItem.data.question_date ||
                            questionItem.first_publication_date
                          ),
                          "PPP",
                          {
                            locale: ro,
                          }
                        )} `}
                      </Typography>
                      <Typography
                        component="h1"
                        variant="h1"
                        className={classes.date}
                      >
                        {questionItem.data.title.text}
                      </Typography>
                      <br />
                      <Typography component="p" className={classes.question}>
                        {questionItem.data.content.text}
                      </Typography>
                      <br />

                      <Grid container direction="column">
                        <Grid item>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <Avatar
                                alt="MONZA ARES"
                                src={Logo}
                                className={classes.avatar}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="h2" component="h2">
                                Sfatul medicului:
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item dangerouslySetInnerHTML={{
                          __html: questionItem.data.reply.html,
                        }}>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default QuestionItemPage

export const query = graphql`
  query PrismicQuestionItemBySlug($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    questionItem: prismicQuestion(uid: { eq: $slug }, lang: { eq: $locale }) {
      id
      lang
      data {
        question_date
        old_id
        old_slug
        reply {
          html
        }
        content {
          text
        }
        reply_author
        reply_date
        schema_json {
          text
        }
        title {
          text
        }

        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
        noindex
      }
    }
  }
`

QuestionItemPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    questionItem: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
